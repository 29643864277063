import styles from "./parliament.module.css";
const IovolImage = "/images/iovol.webp";

function Parliament() {
  return (
    <>
      <section className={styles["container"]}>
        <div className={styles["what-is"]}>
          <div className={styles["text-box"]}>
            <p>What is the Parliament?</p>
          </div>
          <div className={styles["image-box"]}>
            <div className={styles["overlay"]}></div>
            <div className={styles["parliament-logo"]}>
              <img src="/images/iovol.webp" alt=""></img>
            </div>
            <div className={styles["infobox"]}>
              <p>
                The Parliament is a group of like-minded individuals who love to
                play Star Citizen with the sole focus of having fun. There is no
                milsim, RP, rank structures, or anything of that sort within the
                parliament. The group was started by Iovol who was looking to
                make a central group for{" "}
                <a href="https://www.twitch.tv/iovol" target="_blank">
                  his streaming
                </a>
                . The gorup has grown to now include several others who also
                stream or make videos while just having fun.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={styles["container2"]}>
        <div className={styles["video-box"]}>
          <div className={styles["header"]}>
            <h1>What to Expect</h1>
          </div>
          <div className={styles["video"]}>
            <div className={styles["iframe-container"]}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/97ZnPyAmXY0?si=tHW0pCnECxWoUoO0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    <section className={styles["remainder"]}>
      <section className={styles["container3"]}>
        <div className={styles["head"]}>
          <h1>What are you waiting for?</h1>
        </div>

        <div className={styles["buttons"]}>
          <a
            href="https://discord.gg/36hhCB7J9U"
            target="_blank"
            className={styles["hero-btn"]}
            id={styles["discord-link"]}
          >
            <i className="fa-brands fa-discord"></i> Discord
          </a>
          <a
            href="https://robertsspaceindustries.com/orgs/QUEST"
            target="_blank"
            className={styles["hero-btn"]}
            id={styles["spectrum-link"]}
          >
            <i className="fa-solid fa-file"></i> Spectrum
          </a>
        </div>
      </section>
    </section>

    </>
  );
}

export default Parliament;
