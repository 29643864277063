import React, { useState } from 'react';
import styles from './cargoReference.module.css';

const Drake = '/images/Cargo Reference/Thumbnails/Standard/Drake.webp';
const RSIAegis = '/images/Cargo Reference/Thumbnails/Standard/aegis.webp';
const Crusader = '/images/Cargo Reference/Thumbnails/Standard/Crusader.webp';
const MISC = '/images/Cargo Reference/Thumbnails/Standard/misc.webp';
const Origin = '/images/Cargo Reference/Thumbnails/Standard/Origin.webp';
const Other = '/images/Cargo Reference/Thumbnails/Standard/others.webp';
const MainImageSrc = '/images/Cargo Reference/other_cargo.webp';

const DrakeMain = '/images/Cargo Reference/drake_cargo.webp';
const MiscMain = '/images/Cargo Reference/misc_cargo.webp'; 
const RSIAegisMain = '/images/Cargo Reference/rsi_aegis_cargo.webp';
const CrusaderMain = '/images/Cargo Reference/crusader_cargo.webp';
const OriginMain = '/images/Cargo Reference/origin_cargo.webp';
const otherMain = '/images/Cargo Reference/other_cargo.webp';

function CargoGrids() {
    const [mainImage, setMainImage] = useState(MainImageSrc);

    const handleThumbnailClick = (newImageSrc) => {
        setMainImage(newImageSrc);
    };

    return (
        <>
            <section className={styles['whole-page']}>
            <section className={styles["primary"]}>
                <div className={styles["primary-background"]}></div>

                <div className={styles["primary-content"]}>
                    <div className={styles["course"]}>
                        <div className={styles["main-image-container"]}>
                            <img id={styles["main-image"]} src={mainImage} alt="Main Image" />
                        </div>

                        <div className={styles["thumbnail-container"]}>
                            <div className={styles["thumbnails"]}>
                                <img
                                    className={styles["thumbnail"]}
                                    src={Drake}
                                    alt="Drake"
                                    onClick={() => handleThumbnailClick('https://robertsspaceindustries.com/i/139825874cc63c223c8214b38f454b4ff6570b23/resize(1464,1464,inside,JLypLpqVPBaNxs8FRN3D3vuTrjntZTaECi8ToQLLZiEgiUjcwEzuY511JgyJBk2nwU4KBWPC3t64FJzEsV4pZ31167yucQPoWTLPafq1bJsTHMyiBBPEBvUw1HzhJZ5UTmbRe5xLMmeL7vxCs7zrharNDtFMTJxoRNaz4cfXWxNgWYcRbyMQ9B79UZXk9uL4v2)/ac076d49-7c6b-45f5-a35c-be2c8f62dd39.png')}
                                />
                                <img
                                    className={styles["thumbnail"]}
                                    src={RSIAegis}
                                    alt="Aegis"
                                    onClick={() => handleThumbnailClick('https://robertsspaceindustries.com/i/f8e0706939ad9d3ea4c1668024cf56b3836fd30e/resize(1464,1464,inside,JLypLpqVPBaNxs8FRN3D3vuTrjntZTaECi8ToQLLZiEgiUjcwEzuY511JgyJBk2nwU4KBWPC3t64FJzEsV4pZ31167yucQPoWTLPafq1bJsTHMyiBBPEBvUw1HzhJZ5UTmbRe5xLMmeLAHS8wFf2yzMD5ZCfopFSY7GQ7XRvY15y5ytQSN1uiNphAYdD6Sq3JN)/b4826dcf-9534-4f07-a567-61a05b157ab0.png')}
                                />
                                <img
                                    className={styles["thumbnail"]}
                                    src={Crusader}
                                    alt="Crusader"
                                    onClick={() => handleThumbnailClick('https://robertsspaceindustries.com/i/aceae0980e2858e019f6e2d9f03d537c407ee12a/resize(1464,1464,inside,JLypLpqVPBaNxs8FRN3D3vuTrjntZTaECi8ToQLLZiEgiUjcwEzuY511JgyJBk2nwU4KBWPC3t64FJzEsV4pZ31167yucQPoWTLPafq1bJsTHMyiBBPEBvUw1HzhJZ5UTmbRe5xLMmeHskYkAkxhnxAa7Yxwq2PAPETrE4EFJCGFnxhhJx7AHMa9UEZv78SzcW)/4769c245-ef93-4f2d-b599-721d32efefbe.png')}
                                />
                                <img
                                    className={styles["thumbnail"]}
                                    src={MISC}
                                    alt="MISC"
                                    onClick={() => handleThumbnailClick('https://robertsspaceindustries.com/i/e1bd522f13712f8e6c04ed58b85851d860dbad1d/resize(1464,1464,inside,JLypLpqVPBaNxs8FRN3D3vuTrjntZTaECi8ToQLLZiEgiUjcwEzuY511JgyJBk2nwU4KBWPC3t64FJzEsV4pZ31167yucQPoWTLPafq1bJsTHMyiBBPEBvUw1HzhJZ5UTmbRe5xLMmeJ5GcVhEQ5XcaMnnb4p7vM7nVkaFvpbpNwDdqPcGDJFbQ9X6cDF3rdZG)/86fa6ff3-fdf6-482f-b6b1-f6cfa7939c72.png')}  
                                />
                                <img
                                    className={styles["thumbnail"]}
                                    src={Origin}
                                    alt="Origin"
                                    onClick={() => handleThumbnailClick('https://robertsspaceindustries.com/i/d54f42a04350548f157edca9b67d7609f02b8fb1/resize(1464,1464,inside,JLypLpqVPBaNxs8FRN3D3vuTrjntZTaECi8ToQLLZiEgiUjcwEzuY511JgyJBk2nwU4KBWPC3t64FJzEsV4pZ31167yucQPoWTLPafq1bJsTHMyiBBPEBvUw1HzhJZ5UTmbRe5xLMmeLG1sqps8s2sBNpdHw3ynXcFtK8Sr92HDx9DVGz7BJBu7RcLQHwbeCHL)/d1a32e69-2c9c-403c-9c59-605698339141.png')}
                                />
                                <img
                                    className={styles["thumbnail"]}
                                    src={Other}
                                    alt="Others"
                                    onClick={() => handleThumbnailClick('https://robertsspaceindustries.com/i/c759f1acce952026fc5a5acd9f87b32311ac3b77/resize(1464,1464,inside,JLypLpqVPBaNxs8FRN3D3vuTrjntZTaECi8ToQLLZiEgiUjcwEzuY511JgyJBk2nwU4KBWPC3t64FJzEsV4pZ31167yucQPoWTLPafq1bJsTHMyiBBPEBvUw1HzhJZ5UTmbRe5xLMmeHtFPNBKg67Yrb3sLnAzex6jFPFmbcCJzqTEZ5YLzrZcj2jpVRWByyzW)/4ca32588-59a5-4835-8820-dbcfb7c59ff9.png')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer className={styles["footer"]}>
                <div className={styles["source"]}>
                    <div className={styles["source-location"]}>
                        <p>Source: <a href="https://robertsspaceindustries.com/community-hub/post/3-22-ship-cargo-grid-reference-sheets-fTX3VoNJNa2qQ" target='_blank'>Star Citizen Community Hub, by Erec</a></p>
                    </div>
                </div>
            </footer>
           </section>
        </>
    );
}

export default CargoGrids;
