import { useState, useEffect } from 'react';
import styles from '../Medical/medical.module.css?v=1.0';
import { Helmet } from 'react-helmet';

const pamphletMedicalImage = '/images/pamphlet_medical.webp';
const SalvagePamphlet = '/images/pamphlet_salvage.webp';
const cigSalv = '/images/cig-salv.webp';
const cigMed = '/images/cig-med.webp';
const background = '/images/space.webp'

function Medical() {
    const [lightboxImage, setLightboxImage] = useState(null); // State for lightbox
    const [fadingOption, setFadingin] = useState(false);
    const [preloadedImages, setPreloadedImages] = useState({}); // To track preloaded images

    // Preload images on component mount
    useEffect(() => {
        const imagesToPreload = [pamphletMedicalImage, SalvagePamphlet];
        const preloaded = {};

        imagesToPreload.forEach((src) => {
            const img = new Image();
            img.src = src;
            preloaded[src] = img; // Store preloaded image
        });

        setPreloadedImages(preloaded);
    }, []);

    const openLightbox = (image) => {
        setLightboxImage(image); // Set the image to display in the lightbox
        setFadingin(true);
    };

    const closeLightbox = () => {
        setLightboxImage(null); // Close the lightbox
        setFadingin(false);
    };

    return (
        <>
            <Helmet>
                <link rel="preload" href={cigMed} as="image" />
                <link rel="preload" href={cigSalv} as="image" />
                <link rel="preload" href={background} as="image" />
            </Helmet>
      
                <section className={styles["primary"]}>
                    <div className={styles["primary-background"]}>
                        <div className={styles["primary-content"]}>
                            <div className={styles['header']}>
                                <h1>Community Guides</h1>
                            </div>
                            <div className={styles["course"]}>
                                {/* Images */}
                                <div className={styles['image-container']} id={styles['container1']}>
                                    <img
                                        src={cigMed}
                                        alt="Pamphlet Medical"
                                        className={styles["thumbnail"]}
                                        onClick={() => openLightbox(pamphletMedicalImage)}
                                    />
                                    <div className={styles['overlay-text']}>Medical</div>
                                </div>
                                <div className={styles['image-container']}>
                                    <img
                                        src={cigSalv}
                                        alt="Pamphlet Salvage"
                                        className={styles["thumbnail"]}
                                        onClick={() => openLightbox(SalvagePamphlet)}
                                    />
                                    <div className={styles['overlay-text']}>Salvage</div>
                                </div>
                            </div>
                            <div className={styles["information"]}>
                                <p>
                               Both of these guides were thoughtfully created by EmmaInAPinch, a dedicated member of the <a href='https://robertsspaceindustries.com/orgs/FSAR' target='_blank'>Firefly Search and Rescue</a> organization. These guides are community-driven resources designed to assist players, but please note they were last updated during the 3.18 patch. While they remain highly informative, some details may have changed with subsequent updates. For the most current information directly from Cloud Imperium Games (CIG), please refer to the official guides for <a href='https://robertsspaceindustries.com/comm-link/transmission/20047-Medical-Guide' target='_blank'>Medical</a> and <a href='https://robertsspaceindustries.com/comm-link/transmission/19070-Salvage-Repair-Guide' target='_blank'>Salvage</a>.                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Lightbox */}
                {lightboxImage && (
                    <div className={styles["lightbox"]} onClick={closeLightbox}>
                        <div className={styles["lightbox-content"]}>
                            <img
                                src={lightboxImage}
                                alt="Enlarged View"
                                className={`${styles["lightbox-image"]} ${
                                    fadingOption ? styles["fade-in"] : styles["fade-out"]
                                }`}
                            />
                        </div>
                    </div>
                )}
            
        </>
    );
}

export default Medical;
