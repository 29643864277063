import styles from './Player-Lookup.module.css';
import { useState } from 'react';
import axios from 'axios';
import { DateTime } from 'luxon';

function PlayerLookup() {
    const [playerName, setPlayerName] = useState("");
    const [isLoading, setIsLoading] = useState(false); // Track loading state
    const [playerPicture, setPlayerPicture] = useState(null); // State for storing player picture URL
    const [displayName, setDisplayName] = useState(null);
    const [playerHandle, setPlayerHandle] = useState(null);
    const [enlisted, setEnlisted] = useState(null);
    const [ueeNumber, setUEENumber] = useState(null);
    const [playerLocation, setPlayerLocation] = useState(null);
    const [primaryOrg, setPrimaryOrg] = useState(null);
    const [bio, setBio] = useState(null);
    const [url, setUrl] = useState(null);
    const [orgpiclink, setOrgPicLink] = useState(null);
    const [otherOrgs, setOtherOrgs] = useState(null);
    const [isSearched, setIsSearched] = useState(false);




    const handleInputChange = (event) => {
        setPlayerName(event.target.value);
    };

    const handleSubmit = async () => {
        setIsLoading(true);  // Show the loading indicator when the request starts
        setIsSearched(false)
        setPlayerPicture(null); // Reset the player picture when a new search starts
        setDisplayName(null)
        setPlayerHandle(null)
        setEnlisted(null)
        setUEENumber(null)
        setPlayerLocation(null)
        setPrimaryOrg(null)
        setBio(null)
        setUrl(null)
        setOrgPicLink(null)
        setOtherOrgs(null)
        
    
        try {
            // First, make the Star Citizen API request
            let response = await axios.get(
                `https://api.starcitizen-api.com/hsAANaJtqcjC1JS7hvUZTRToyBxQRVbY/v1/cache/user/${playerName}`
            );
    
            let data = response.data.data;
         
            if (data == null) {
                // If cache is empty, check live version
                response = await axios.get(
                    `https://api.starcitizen-api.com/hsAANaJtqcjC1JS7hvUZTRToyBxQRVbY/v1/live/user/${playerName}`
                );
                data = response.data.data;
            }
    
            if (data.profile == undefined || data.profile == null) {
                return alert('Could not find player');
            } else {
                // Process the received data and update states
            // Now make the database request
            
            let database = await axios.get(
                `https://www.ratjack.net:3000/api/player-find?playerName=${encodeURIComponent(playerName)}&ueeNumber=${encodeURIComponent(data.profile.id)}`
            );
            const result2 = database.data

            if(result2.message){
                console.log('player not found')
            }else{
                
                const friendly = result2.friendly
                const griefer = result2.griefer
                const murderHobo = result2.murderHobo
                const pirate = result2.pirate
                const suspicious = result2.suspicious

                // if (data.previousNames && data.previousNames.length > 0) {
                //     const previousNamesList = data.previousNames
                //         .map(name => name.trim()) // Optional: trim whitespace
                //         .join('<br>'); // Join with <br> for line breaks in HTML
                //     setPreviousNames(previousNamesList); // Assuming you have a state for this
                // }
            }


            

                const playerPicture = data.profile.image;
                setPlayerPicture(playerPicture);
                setDisplayName(data.profile.display);
                setPlayerHandle(data.profile.handle);
                const dateParts = data.profile.enlisted.split('T')[0].split('-'); // Split into [YYYY, MM, DD]
                setEnlisted(`${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`);   // Rearrange to MM-DD-YYYY

                setUEENumber(data.profile.id);
                setPrimaryOrg(`<a href="https://robertsspaceindustries.com/orgs/${encodeURIComponent(data.organization.sid)}" target="_blank" rel="noopener noreferrer">${data.organization.name}</a>`);
                setBio(data.profile.bio);
                setUrl(data.profile.page.url);
                setOrgPicLink(data.organization.image);
                console.log(data.organization)
                console.log(data.affiliation)
    
                let otherOrgs = data.affiliation
                .map(org => {
                    if (org.name && org.name.trim().length > 0) {
                        return `<a href="https://robertsspaceindustries.com/orgs/${encodeURIComponent(org.sid)}" target="_blank" rel="noopener noreferrer">${org.name}</a>`;
                    }
                    return '';
                })
                .filter(link => link.length > 0)
                .join('<br>');
            setOtherOrgs(otherOrgs);
                setPlayerLocation(data.profile.location.region + ', ' + data.profile.location.country);
            }
    

    
        } catch (error) {
            console.error('Error during search:', error);
            // Handle any errors related to axios requests
            if (error.response) {
                console.error('Error response:', error.response.data);
            } else {
                console.error('Error message:', error.message);
            }
        } finally {
            setIsSearched(true)
            setIsLoading(false);  // Hide the loading indicator once the request is complete
        }
    };

    return (
        <div className={styles["primary"]}>
            
            <div className={styles["primary-background"]}></div>
            
            <div className={styles["primary-content"]}>
                <div className={styles['input-area']}>

                
                
                <input
                    type="text"
                    className={styles["input-box"]}
                    value={playerName}
                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit();
                        }
                    }}
                    placeholder="Enter player name"
                />
                <button
                    className={styles["submit-button"]}
                    onClick={handleSubmit}
                    disabled={isLoading}  // Disable the button while loading
                >
                    Submit
                </button>
               </div>
                {/* Show spinner below the button if loading */}
                {isLoading && (
                    <div className={styles["loading-container"]}>
                        <div className={styles["spinner"]}></div>
                    </div>
                )}

{/* Display player picture if available */}
<div className={styles['section']} id={styles['section1']}>
<h1>{isSearched ? "Player Information" : ""}</h1>
<div className={styles['result-container']}>
<div className={styles['image']}>
{playerPicture && (

<img src={playerPicture} alt="Player Picture" className={styles["player-image"]} />

)} 
</div>
<div className={styles['primary-info']}>

{displayName && (

<p className={styles["player-detail"]}>Player Name: {displayName}</p>

)}
{playerHandle && (

<p className={styles["player-detail"]}>Player Handle: {playerHandle}</p>

)}
{enlisted && (

<p className={styles["player-detail"]}>Enlisted: {enlisted}</p>

)}
{ueeNumber && (

<p className={styles["player-detail"]}>UEE Number: {ueeNumber}</p>

)}
{playerLocation && (

<p className={styles["player-detail"]}>Location: {playerLocation}</p>

)}
{bio && (

<p className={styles["player-detail"]}>Bio: {bio}</p>

)}
</div>


</div>

</div>

<div className={styles['section']}>
<h1>{isSearched ? "Org Information" : ""}</h1>
<div className={styles['result-container']}>
<div className={styles['image']}>
{orgpiclink && (

<img src={orgpiclink} alt="Org Picture" className={styles["player-image"]} />

)} 
</div>
<div className={styles['primary-info']}>
    
{primaryOrg && (

<p className={styles["player-detail"]}>
<strong>Primary Org:</strong>
<br />
<span dangerouslySetInnerHTML={{ __html: primaryOrg }}></span>


</p>

)}
{otherOrgs && (

<p className={styles["player-detail"]}>
<strong>Other Orgs:</strong>
<br />
<span dangerouslySetInnerHTML={{ __html: otherOrgs }}></span>
</p>

)}
</div>
</div>
</div>



            </div>
        </div>
    );
}

export default PlayerLookup;
