import styles from './equipment.module.css?v=${Date.now()}'
import { Helmet } from 'react-helmet'
const vr = '/images/vr.webp'
const pc = '/images/pc.webp'
const desktop = '/images/desktop.webp'


function Equipment() {
    return (
        <>
            <Helmet>
                <link rel="preload" href={desktop} as="image" />
                <link rel="preload" href={pc} as="image" />
                <link rel="preload" href={vr} as="image" />
            </Helmet>
        <section class={styles["setup"]}>
    <section class={styles["course"]} id={styles['course0']}>
    
        <div class={styles["row"]} id={styles['row0']}>
            <div class={styles["course-img"]} id={styles['course-img0']}>

            </div>
        <div class={styles["course-col"]} id={styles['course-col0']}>
            <h3>PC</h3>
            <p>CPU: <a href="https://www.amazon.com/AMD-7950X-32-Thread-Unlocked-Processor/dp/B0BBHD5D8Y/ref=sr_1_2?crid=1RQITUL71LX3V&dib=eyJ2IjoiMSJ9.hs8ZjhfxdFhr2YEgR9IZ3ClyCWFEK_hVTxEaKPA-2vCnYhbbtfrXuaWxSe9EptRT5fIhWBCm56wP9q-imQf5rqsvPUWkM-T53mIi8USzgSVkf1P7erXNzNxCMTxlpDb9il6kDspJpZq82-L4OAsGGP-88IYDPrGxaVFjIV9bDm5HiRL7Oq36ARQAEeKSRi_M4yyahF4dqZtHFU_yjvgkJSvoyDfUOkS9l5tawXIjl2d0hMKJaQIHSaJdl_g3DEcGA3LnUxNgcr9MbSFHiuWLhcdZqTk2mZ5flUWmGuruYhQ.2Hmi3cu0DVJET7dRp2nkyV8IBr7tUpkCfhKBjMrWWUc&dib_tag=se&keywords=7950x3d&qid=1725749163&s=electronics&sprefix=7950x3d%2Celectronics%2C121&sr=1-2" target="_blank">AMD 7950x3D</a> 
                <br></br>GPU: <a href="https://www.amazon.com/MSI-RTX-3080-10G-LHR/dp/B0B4KK7BDW/ref=sr_1_1?crid=T0BS5U0LH02C&dib=eyJ2IjoiMSJ9.cmkHyN60luZxN0h04cOnZLP4q69aC93LbdErgOibmJv4XI_VExEp-h8zTfkhMlTkyteoUR6EUSQEQJHKyKynYIPpFcM3UTUeVhSzRXKJeWRgIfQS4LRV1WjwPyJjje5oYBdTPuYMP8IvL8WBjLFH2LHagjRu9RWBJV_dJkifabvNWDiArFcOnu1u3ay-r9XuSdhWs79cW_gMnDnPY99Du2wZTO9gzwoNbqL6AhtSbKQeE-xCX01KA0k4II-2D1uGSjSM0QJzk6uvyOB4EDqUSsYWa_JIMyKMqujIVdaCDJg.vrdjVws6WfMt4vkfgyYYUMI8FEnWMj9AfnnmWswjwC4&dib_tag=se&keywords=msi+3080+ventus+3x&qid=1725749105&s=electronics&sprefix=msi+3080+ventus+3%2Celectronics%2C120&sr=1-1" target="_blank">MSI 3080</a>
                <br></br>Motherboard: <a href="https://www.amazon.com/ASUS-ROG-B650E-F-LGA1718-Motherboard/dp/B0BHMTYZKZ/ref=sr_1_1?crid=1Q89NIOVCXF0W&dib=eyJ2IjoiMSJ9.eGA0RhtCmcNNd3F1gDW23EPSBMH4_7INv7Kb5a3IgkTvnoWID4r98lCxdO_sBhyBgpdurbPOqzJqWZDevM9g-Hp8wHt262XQQ15uAkDOiNH11fn1wtRMsC5ZtZ_O8WoCexwDFRfcUwEIYpQcuTtq2Cuzi__Cjwlx45z3iPEBlYwqxyqjpQZiNdIesZjXc1sZn6edetEaXdMPbwCqxV3bPkPx-RZ2lEEwDO23NEwZvInEWVLTlhnrVqTCi9qJJWe4RHwoq5r5caO942VkmvhwFrO4adkZLZjxTEtFYK_S6J4.sMTo4O1znjKc6Og0JUH0iyrfr56FO_Y1OZ0LD_DF5Y8&dib_tag=se&keywords=b650EF&qid=1725749215&s=electronics&sprefix=b650ef%2Celectronics%2C130&sr=1-1" target="_blank">ASUS B650E-F</a> 
                <br></br>PSU: <a href="https://www.amazon.com/Corsair-RM850x-Fully-Modular-Supply/dp/B08R5JPTMZ/ref=sr_1_1?crid=UMCE6S1KG36B&dib=eyJ2IjoiMSJ9.LE0KKpEz8USqrNJxTEEc4vb_WwVUcEIX9hZ9si8fjwkwAj5px2G7_Mk9PeULzGZ2YBTqxaFQR4bClZTTchffiF27kiljpmjDFf6sO0kDoLcqiM5PB1_x875enLMhVSnBiTh3ysy6POwxEh_ecupoDpU5wWdfJowHi6r6g8qJvkgymLS4gUnK-2AM9vAb_7bCOiBtUC8rzX2BYrL6co-eS5J1je5AVz5BiHwdm75Q2zg.L9J97icXnRJ2JODn5zHV3ffkOdcQK85eH-_oTR9Robc&dib_tag=se&keywords=rm850x&qid=1725751330&sprefix=rm850x%2Caps%2C128&sr=8-1" target="_blank">Corsair RM850X</a>
                <br></br>Monitor: <a href="https://www.amazon.com/gp/product/B08DWD38VX/ref=ppx_yo_dt_b_search_asin_title?ie=UTF8&psc=1">LG 34GL750-B</a>
                <br></br>Second Monitor: <a href="https://www.costco.com/msi-27-wqhd-ips-nvidia-g-sync-gaming-monitor.product.4000203457.html" target="_blank">MSI G274QPF</a>
                <br></br>Third Monitor: LG 24M47H-P
                <br></br>Mouse: <a href="https://www.amazon.com/Razer-Basilisk-Customizable-Ergonomic-Gaming/dp/B09C13PZX7/ref=sr_1_2?crid=3F1WU98B6IGNZ&dib=eyJ2IjoiMSJ9.7eMJ7lC1RPWUSB2mefXAYysBKoxW35HBMU1E0eRXDB_LDtd7I4sQUJgZCGOUkVD9Rn1pAfXRSX24mfTBmuzq-ZrTDm7w1KGDRMJRCCzpb2DIFaA31cr9xffsYuS_scE2ckbyR9pQ8zsk3X3NqYOe7xQ9O7n6cJn2iZ3ZJfVZYy-T48Z12D09GOZe8tBkDMP303KD1BVbSkn6Tbl0cH4m0g3mceoKIZf5bdym1u4gk-c.ybZ4-wePcxyx8Q8x9A4orfttccukOaZ2Ic7ZR3maums&dib_tag=se&keywords=basilisk+v3&qid=1725751349&sprefix=basilisk+v3%2Caps%2C152&sr=8-2">Razer Basilisk V3</a>
                <br></br>Keyboard: <a href="https://www.amazon.com/ASUS-II-Switch-Dampening-Hot-Swappable-PBT/dp/B0C7KFZ5TL/ref=sxin_16_pa_sp_search_thematic_sspa?content-id=amzn1.sym.140400a7-1208-46ad-8d2a-eb6e8eac81b5%3Aamzn1.sym.140400a7-1208-46ad-8d2a-eb6e8eac81b5&crid=33J4IYP6Z3L2D&cv_ct_cx=asus+scope+ii&dib=eyJ2IjoiMSJ9._SVikdTxrB9J5y0ikpisHwvvvIZQI-YbpsWsNqTchu2xQZx6R-mXpVM99C-KnUX3.DQyOuyIVcaQJOP59MWzTp0RwPvnUcOd6AjIOa8lpqKk&dib_tag=se&keywords=asus+scope+ii&pd_rd_i=B0C7KFZ5TL&pd_rd_r=7b77aade-9939-49ac-a116-b810652ae1bc&pd_rd_w=ecMrE&pd_rd_wg=fufBz&pf_rd_p=140400a7-1208-46ad-8d2a-eb6e8eac81b5&pf_rd_r=K563WZYEV450V0BEA89J&qid=1725751378&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sprefix=asus+scope+ii%2Caps%2C140&sr=1-1-6024b2a3-78e4-4fed-8fed-e1613be3bcce-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9zZWFyY2hfdGhlbWF0aWM&psc=1" target="_blank">Asus Scope II 96%</a>
                <br></br>Joysticks: <a href="https://www.vkbcontrollers.com/products/gladiator-nxt-evo-space-combat-edition-right-hand" target="_blank">Gladiator NXT Evo HOSAS</a>
                <br></br>*Stick Mounts: <span class="redacted"></span>
            </p>
            <p>*The company who I got my mounts from has recently done some shady stuff so I instead recommend looking into <a href="https://www.monster.tech/en/">Monstertech</a> or <a href="https://foxxmount.com/">Foxx</a></p>
               
        </div>

    </div>



</section>
<section class={styles["course"]} id={styles['course1']}>
<div class={styles["row"]} id={styles['row1']}>

    <div class={styles["course-img"]} id={styles['course-img1']}></div>
             <div class={styles["course-col"]}>
            <h3>Streaming</h3>
            <p>Camera: <a href="https://www.amazon.com/Canon-EOS-Mark-Mirrorless-Oversampling/dp/B0BL7ZVY78/ref=sr_1_3?crid=22TGWDU7ZTP07&dib=eyJ2IjoiMSJ9.gR2U2emcTL_UQbrNJYuudlWccTpccHo-H5biwprmP7_NkKZdXq5SVT8wJpCOeLcydoeVm3CyXXBEV_3FNRgR64fnZAQ6OgQEeRd2G04d9tzJPNbfBcFvb8y1oCQtHdUzSlYjKP0ZBF80_hG25cx9638Ke3WLoGoXTGBMwvmcJ7iwbWyg-9ab6aGFIIW9UKKWXZAazL4M8riWcK8mAxLB8wi0SLe3q8SoEqi_OlikFsDfAfSEO6OJVn5vehn68RVetw9QyRjHCsI7oZSpiTAqXw78VGhFK4_fdGhZgu8He9A.JX0o4IUL8sormbrNEYndhZgzUzBbkf2wotdTLGlDk6E&dib_tag=se&keywords=canon+r6+mark+ii&qid=1725751606&s=electronics&sprefix=r6+%2Celectronics%2C139&sr=1-3">Canon R6 Mark II</a> 
            <br></br>Lens: <a href="https://www.amazon.com/Tamron-35mm-1-4-Lens-Canon/dp/B07STH2LJX/ref=sr_1_3?crid=13Q29PG025EQ0&dib=eyJ2IjoiMSJ9.WaawzZnc7R-FCcj51694orr1SYidQFk0dZqQVhgZXti8UtQLDhOSv8ylCpci2oFT1nWCQwjECIDVVXmyPkqriNLZz3RUZFUliNNc0uf5CTlEG8Eo-EjYjWvTua0TJIGFAbIIQwALVOI5R6I7D2P3kkDFYY8Lg3EXyp2Zw5j69ckZfDx-DYlQD2ARzv_j0l_rhGIQmFnGgrVOuwAh-Zp39C-7bqvOYQ-WKQPmpvqBD3bDU2Ddtxo4ikoeNtb0kWvBbdKctmiN2zR12q5LwbTCrWtKvXsMf_DXSbgC7g3OjTs.tXbnPbArFhOHJMCgR2aGgy6FmU5Wd4DmC9T3MArsH_M&dib_tag=se&keywords=Tamron+SP+35mm&qid=1725751543&s=electronics&sprefix=tamron+sp+35mm%2Celectronics%2C113&sr=1-3">Tamron SP 35mm f/1.4 di USD</a>
            <br></br>Capture Card: <a href="https://www.amazon.com/Elgato-Cam-Link-Broadcast-Camcorder/dp/B07K3FN5MR/ref=sr_1_3_pp?crid=125KS7TLLNI3U&dib=eyJ2IjoiMSJ9.IwwKQI6yyiawGkE3L9xwV84StdooG9NmMTq94V6vOCoWhJYybauJyRKCVrVhVzOUHinZdXRbYGtd0iqfjpbdkIbqJhTYcdslS5BcPGuCYY_8e75uXnBh7XM3vi-GMAwWgav6-c5rpjol4hIH5e3F88dcsspvaymTAq3qquJ-X-acyirsikascSDM7msFoZ4SOJU2iTvJcIYzRz4TssYniUSr5a4c4-jypFk1ak4ZEhM.jToBQVbz_KpwCvn7SMTiTzPD-H-5xR21vN8UI-6ymhA&dib_tag=se&keywords=cam+link+4k&qid=1725752207&sprefix=cam+link+4k%2Caps%2C154&sr=8-3" target="_blank">Elgato CamLink 4k</a>
            <br></br>Main Light: <a href="https://www.amazon.com/SL60IID-5600%C2%B1200K-Brightness-Recording-Shooting/dp/B0BWDQR3HZ/ref=sr_1_2_sspa?crid=XJ1D8K3XCT9B&dib=eyJ2IjoiMSJ9.aw_9sVnQ3FXMEoReusoNKb445DV0cJ_f25fyUavDj7lqtstAG9mC-Y2SJ_ezk1nXBIebKPdWH-UbsR_Hp6MvEL1kTBBr5pMj9TA0BHKAGe-ck9joXC1-M0TbZguJvb25NIfENGzvu7dZktGMRCO2irmwVpqNVUsdzNpIIIJBPwYWDzsMwwsZ5AvVWJF8bHpymMnkxgssigOIMLqT4y9aokBWIU1MNSOF11rkrFcC3AZCq9vxk3tJL-jzRj2ve4G5myhYHr-P54-WRcoGh-bQDxywz7N5iKtdYYg5oEJmn8w._R1dOuZIeQbX32xefokisfW9tOJ3nBpcn5XVtOo5E-Q&dib_tag=se&keywords=godox+sl60&qid=1725751654&s=electronics&sprefix=godox+sl60%2Celectronics%2C132&sr=1-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1" target="_blank">Godox SL60</a>
            <br></br>Modifier: <a href="https://www.adorama.com/glbdez34s.html" target="_blank">Glow EZ Lock Beuty Dish 34"</a>
            <br></br>Fill Light: <a href="https://www.amazon.com/gp/product/B07XMRDFWK/ref=ppx_yo_dt_b_search_asin_title?ie=UTF8&psc=1" target="_blank">Dazzne D50</a>
            <br></br>Back Lights: <a href="https://www.amazon.com/Govee-Matter-1725lm-Corner-Bedroom/dp/B0CTH2QF23/ref=sr_1_8?dib=eyJ2IjoiMSJ9.t9rcwwrJ7Wz7i4AiQTHss3KuPNKIfxBF7ndCknltpmJnLrlKokMKt4oaI_1jMf6-nOHp2vx7r6cS48Z5QbFQ6VugChwdJL3OO7FUIAOF_Ho6w5KMj-uI12ML8zqT1fczyYHrsjxCE8JRog1BH2SfbJ7ZV4VTMbXu1gyhn2CgPurwtpX5PPpg_HT-2eVZ-GXxKQEBB1ZmYFFL7R5PGV3XVj205mnTGi1qOdnopiPXdsdm5n0mtOwdpLg7qPn-O5vxmGYmxbrEuYXKiL8pfS-jBTY4_M0qs3ws0okeBH7bT_0.RPH4AAwhH43KEi_N30hXWm_HJDdg-mrH-iMzY-uUcCc&dib_tag=se&keywords=govee%2Bpro&qid=1725751870&sr=8-8&th=1" target="_blank">Govee Floor Lamp Pro</a>
            <br></br>Stream Deck: <a href="https://www.amazon.com/Elgato-Stream-Deck-XL-customizable/dp/B07RL8H55Z/ref=sr_1_1?crid=35GKYT6REWW0Y&dib=eyJ2IjoiMSJ9.3e7r2_WlFipmynQmyTpMCNJiUONLO3I50a-Sb-NiCJSWkhYshDvMQ91MbsJdxMukpc-QHGPNRdBLUuq4Tj96UX2sdSlQUIMvmSUdFsNtXYOZnl38OVZzCp3LnVh4ujySlVc4WRo-K7jc7Sxuj3YD4wIcvfT628ptiyR7RGRany3P65rhQch5MKaVWcVagd5oQ8SzklzCvc3-SBnQjbNHK2eqVQJlIbWx-d2J2iBV_7o.8-Erar2CyDfBQUMQNL9TPRMaqt3KHhJhuIIvYChrBNc&dib_tag=se&keywords=Stream%2Bdeck%2Bxl&qid=1725752067&sprefix=stream%2Bdeck%2Bxl%2Caps%2C129&sr=8-1&th=1" target="_blank">Elgato Stream Deck XL</a>
            <br></br>Second Stream Deck: <a href="https://www.amazon.com/Elgato-10GBA9901-Trigger-Actions-Compatible/dp/B09738CV2G/ref=sr_1_1?crid=21LMPYMXUHK70&dib=eyJ2IjoiMSJ9.DnKGuy9SJK53bS0om0K6ANJiUONLO3I50a-Sb-NiCJS_3IDMkRCsWrWVlyExqxqIoH0-FY2NTzi_PapRgf75w5EqKOVgbst7_YHDb5XmqUk.fcWkYstpV7ElcoKkZ7Ow2NtCWdETrHKYtLk86K73JyU&dib_tag=se&keywords=Stream+deck+mk1&qid=1725752073&sprefix=stream+deck+mk1%2Caps%2C128&sr=8-1" target="_blank">Elgato Stream Deck MK1</a>
            <br></br>Pedals: <a href="https://www.amazon.com/Elgato-Stream-Deck-Pedal-footswitches/dp/B09PRMCTGB/ref=sr_1_1?crid=3EX4FVNHMNP82&dib=eyJ2IjoiMSJ9.ZJeLOBOqWJ_WPGp-PXoAhPFgT0wIJ9v0N78vIY37qo373FQt7El44kA9h2cDKFT-P6X64H-PYZyebvpmaq8PUaBd-ZsPX4MsGMcOz7wbakNZiwUDyzTCLUMsGIt6IHkz6uHchI4LvZOT8PedBQUbov2waiuUfpWRfeEO-q-VspA.6JRKw-F5focddgjrHuoQ97RqOLcUGq7snMKfwyUfBZ0&dib_tag=se&keywords=elgato+pedal&qid=1725752133&sprefix=elgato+pedal%2Caps%2C151&sr=8-1" target="_blank">Elgato Stream Deck Pedal</a>
            <br></br>Audio Interace: <a href="https://www.amazon.com/Elgato-Wave-Tap-Mute-Mac/dp/B09738CKKX/ref=sr_1_1?crid=3OCHWVKABRVRA&dib=eyJ2IjoiMSJ9.BJkLWEmrhTlEYObMOW0W_AwKBdH3mdfPTRMNtxqrGZUFAE3n69c6J4R23rR4Tokq7sfFmksl0jUH1sCn-FMizZzbTXH95dFVUrz3E5ZfDiNlGGFX3hR7pl_KwIeK_TvMmowIbTPM9l1DqxSwlt36hYDGzCfl2JZJlXMDiBrANqZjBJH1d8WXLSQ6pxUpFAy5F3Z6jdH0i3z1gF243aY2MyX7D-yyNj3EgHseXiFF5ludbgmqh-JQnH5r3j7IMMTQ7N0orsz07-YdvEUHA24SKVGv3zPupySTaIfEnXrSMhY.jrdscOFa186iEdL0mmaeYZAcfR_HbW4C7Vy1GOebqnQ&dib_tag=se&keywords=wave+xlr&qid=1725752308&sprefix=wave+xlr%2Caps%2C149&sr=8-1" target="_blank">Elgato Wave XLR</a>
            <br></br>Microphone : <a href="https://www.amazon.com/Rode-PODMIC-Dynamic-Podcasting-Microphone/dp/B07MSCRCVK/ref=sr_1_2?crid=GU0IKC0DKTHT&dib=eyJ2IjoiMSJ9.hGmoGJDs26hIKVWtnmzla8VZtRDijjMR6kkyG2k2R6jzn710yEjYQS6Gw2c1LB_tw3G3W0kKHkINI9lb9Ufu2NJAdZ955QxD6-mM6VjJkRvuMb-v-R7w1BfFTpQ92IUx4OiK2uIQ3RdwN3dildgVtXABitscbY6Jf5Clfeog_6OtILCLYVG2IhfPH6ugref5UV0ezBo4E33y5PoRF8uJvR7lzhV0P3Low5vpw7NP6UJzv6adDYi6TWmrk8pGnjNQm8wXsr8i9AYsMssksvvk5dPS3HZz-oM_40yXJE-cRDY.vcZR6kv25j5oLjz54KQagdDYr8dtPIKhGwr9kL_3fCA&dib_tag=se&keywords=podmic&qid=1725752354&sprefix=podmic%2Caps%2C135&sr=8-2" target="_blank">Rode Podmic</a>
        </p>
        </div> 
</div>
</section>
<section class={styles["course"]} id={styles['course2']}>
    <div class={styles["row"]} id={styles['row2']}>
         <div class={styles["course-img"]} id={styles['course-img2']}></div>
    <div class={styles["course-col"]} id={styles['course-col2']}>
        <h3>Virtual Reality</h3>
        <p>Headset: <a href="https://www.amazon.com/Meta-Quest-512GB-Breakthrough-Reality-3/dp/B0CD1JTBSC/ref=sr_1_3?crid=22PTD5V6R2SE3&dib=eyJ2IjoiMSJ9.xRsvnlX4LHo4uh9JQja8IZmv-ZrHbeyzQdvBuobqH37carQeOyMiXzUBQCflK1FLIvVs3_x5ifJJsN_Equn1AXVmxeScN9JoxPdwkjTOhPy-THerkcAXhJtd3ck5ZlcRSvxXe40s1M_JVbF3rv8iogVE0pKp2Q59lzImcGVdrvb8iVJkdr_30rB7Gk6V2n7Rhl0-n1uxDms6Bp0ZFmAJXw08wvlQ0wycREqj4WqPTdo.FY74ETfOEw5J5KRJbE3dN4zDq2AYUcFNT0jJmgBIw0o&dib_tag=se&keywords=quest+3&qid=1725752643&sprefix=quest+3%2Caps%2C138&sr=8-3" target="_blank">Quest 3 512GB</a>
        <br></br>Headstrap: <a href="https://www.amazon.com/BOBOVR-Pro-Accessories-conditioning-hot-swappable/dp/B0CSY8PD63/ref=sr_1_1_sspa?crid=22PTD5V6R2SE3&dib=eyJ2IjoiMSJ9.xRsvnlX4LHo4uh9JQja8IZmv-ZrHbeyzQdvBuobqH37carQeOyMiXzUBQCflK1FLIvVs3_x5ifJJsN_Equn1AXVmxeScN9JoxPdwkjTOhPy-THerkcAXhJtd3ck5ZlcRSvxXe40s1M_JVbF3rv8iogVE0pKp2Q59lzImcGVdrvb8iVJkdr_30rB7Gk6V2n7Rhl0-n1uxDms6Bp0ZFmAJXw08wvlQ0wycREqj4WqPTdo.FY74ETfOEw5J5KRJbE3dN4zDq2AYUcFNT0jJmgBIw0o&dib_tag=se&keywords=quest+3&qid=1725752643&sprefix=quest+3%2Caps%2C138&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1" target="_blank">BoboVR S3 Pro</a>
        <br></br>Gunstock: <a href="https://www.protubevr.com/en/rifle-and-assault-weapon/537-7444-magtube-vr-gunstock-quest-3.html#/637-color-black_carbon_fiber/645-armature-black_carbon_fiber_armature/647-sling-2_points_black_sling/651-rifle_stock-adjustable_stock_mk2" target="_blank">ProtubeVR Magtube</a>
        <br></br>Lens Covers: <a href="https://www.amazon.com/Zenni-Anti-Fog-Non-Prescription-Glasses-Accessories/dp/B0CSD64B7B/ref=sr_1_3?crid=2DE2QAYT7QOBK&dib=eyJ2IjoiMSJ9.uuYG2wbeRFG74p0n76-Mqyj8moRyYKfqGgcfswXQWy4VRxUFgtYett-c8HSVWXSE63d3Na7RtugAQTVpLR3dKpEqKOVgbst7_YHDb5XmqUk.DFT_d92jDdSoJZxlTPUx547ZxyPhfWcnmGl7TYPOcpU&dib_tag=se&keywords=quest+3+zenni&qid=1725752741&sprefix=quest+3+zenni%2Caps%2C129&sr=8-3" target="_blank">Zenni</a>
        <br></br>Facial Interface: <a href="https://www.amazon.com/Globular-Cluster-Magnetic-Facial-Interface/dp/B0CXPV7JNH/ref=sr_1_2?crid=3SY1HYRTGEVEL&dib=eyJ2IjoiMSJ9.SyhypP3Z0N6u8xsdX5rTKzXn6C6uB7zuUduCJ7Et8lnGjHj071QN20LucGBJIEps.mPdoMOk2oCwokgdU3-WBrE8PPC_2xkmmwPl2cQT-o3U&dib_tag=se&keywords=quest%2B3%2Bglobular%2Bcluster%2Bf3&qid=1725752759&sprefix=quest%2B3%2Bglobular%2Bcluster%2Bf%2Caps%2C126&sr=8-2&th=1" target="_blank">Globular Cluster F3</a>

        </p>

    </div>
    </div>
   
</section>

</section>
        
        </>
    )
}


export default Equipment;