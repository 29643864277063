import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import HomePage from './Components/Home Page/Home';
import Medical from './Components/Star Citizen/Medical/Medical';
import Header from './Components/Header'; // Make sure this import is correct
import Salvage from './Components/Star Citizen/Salvage/Salvage'
import CargoGrids from './Components/Star Citizen/Cargo Grids/CargoReference'
import OtherTools from './Components/Star Citizen/Other Tools/Other Tools'
import Parliament from './Components/Star Citizen/Parliament/Parliament'
import Equipment from './Components/Equipment/Equipment'
import PlayerLookup from './Components/Star Citizen/Target/Player-Lookup'

function App() {
  return (
    <Router>
      <Header /> {/* Include Header for navigation */}
      <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Guides" element={<Medical />} />
          <Route path="/Salvage" element={<Salvage />} />
          <Route path="/CargoReference" element={<CargoGrids />} />
          <Route path="/OtherTools" element={<OtherTools />} />
          <Route path="/Parliament" element={<Parliament />} />
          <Route path="/Equipment" element={<Equipment />} />
          <Route path="/Player-Lookup" element={<PlayerLookup />} />
          <Route path="/lander" element={<HomePage />} />
          {/* Add more routes here as needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
