import styles from './salvage.module.css?v=1.0'
const SalvagePahmphlet = '/images/pamphlet_salvage.webp'


function Salvage() {

    return (

        <>
        <body>










<section class={styles["primary"]}>

<div class={styles["primary-background"]}></div>

    <div class={styles["primary-content"]}>
<div class={styles["course"]}>

<img src={SalvagePahmphlet} alt="Salvage Pamphlet"></img>

</div>

    </div>

</section>
</body>
        </>

    )
}

export default Salvage;